.energy-calculator {
  &__heading,
  &__button {
    grid-column: 2 / -2;

    @include media(min-tablet) {
      grid-column: 3 / span 8;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;

    z-index: 999;

    width: 100%;
    height: 100%;

    background: rgba(0, 0, 0, 60%);

    transition: opacity 0.5s ease;

    @include media(min-tablet) {
      position: absolute;
    }
  }

  &__iframe-wrap {
    position: absolute;
    top: 50%;
    left: 50%;

    padding: vw(360, 70) vw(360, 10) 0;

    width: 100vw;
    height: 100vh;

    background: var(--color-white);

    opacity: 1 !important;
    border: none;

    transform: translate(-50%, -50%);

    @include media(min-tablet) {
      padding: vw(1440, 90) vw(1440, 60) 0;

      width: vw(1440, 960);
      height: 90vh;
    }
  }

  &__close {
    position: absolute;

    top: vw(360, 5);
    right: vw(360, 5);

    width: vw(360, 56);
    height: vw(360, 56);

    cursor: pointer;
    border: 1px solid var(--color-black);

    border-radius: 100%;

    @include media(min-tablet) {
      top: vw(1440, 15);
      right: vw(1440, 15);

      width: vw(1440, 56);
      height: vw(1440, 56);
    }

    &::before,
    &::after {
      content: '';

      position: absolute;
      top: 50%;
      left: 50%;

      width: vw(360, 16);
      height: 2px;

      background: var(--color-black);

      transform: translate(-50%, -50%) rotate(45deg);

      @include media(min-tablet) {
        width: vw(1440, 16);
      }
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &__iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}
