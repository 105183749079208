.image {
  /* Single */
  &.is-single &__img-wrap {
    grid-column: 3 / 11;

    &.is_landscape {
      grid-column: 2 / 12;

      width: 100%;
    }

    @include media(min-tablet) {
      justify-self: center;

      &.is_portrait {
        width: calc(100% - var(--spacing-120));
      }
    }
  }

  /* panorama */
  &.is-panorama {
    z-index: 10;
  }

  &.is-panorama &__container {
    display: block;
  }

  &.is-panorama &__wrap {
    position: relative;

    margin: 0 auto;

    width: vw(360, 240);
    height: vw(360, 253);

    overflow: hidden;

    cursor: none;
    background-position: 50% 50%;

    background-repeat: no-repeat;
    background-size: auto 100%;
    background-color: var(--color-white);

    @include media(min-tablet) {
      justify-self: center;

      width: vw(1440, 840);
      height: vw(1440, 900);
    }
  }

  &.is-panorama &__close-icon {
    position: absolute;

    top: var(--spacing-15);
    right: var(--spacing-15);

    width: var(--spacing-30);
    height: var(--spacing-30);

    visibility: hidden;
    opacity: 0;

    stroke: var(--color-white);
  }

  &.is-panorama .is-light &__close-icon {
    stroke: var(--color-black);
  }

  &.is-panorama &__drag-overlay {
    display: inline-flex;
    align-items: flex-end;
    justify-content: space-around;

    position: fixed;

    bottom: 0;

    padding-bottom: var(--spacing-30);

    width: 100%;
    height: vw(360, 240);

    visibility: hidden;
    font-size: vw(360, 12);

    text-transform: uppercase;

    color: white;

    background: linear-gradient(180deg, rgba(255, 255, 255, 0%) 0%, #000c 100%);
    opacity: 0;

    @include media(min-desktop) {
      display: none;
    }
  }

  /* Scale */
  &.is-scale &__img-wrap {
    grid-column: 5 / 13;

    margin-right: var(--spacing-60);

    transform: scale(0.33);
    transform-origin: top right;
  }

  &.is-scale &__caption {
    will-change: transform;
  }

  /* Grid */
  &.is-grid &__container {
    @include media(max-tablet) {
      row-gap: var(--spacing-30);
    }
  }

  &.is-grid &__img-wrap {
    will-change: transform;

    .img {
      height: calc(100% + var(--col-1));

      will-change: transform;
    }
  }

  &.is-grid &__img-wrap:nth-of-type(1) {
    grid-column: 2 / 10;

    height: vw(360, 247);

    @include media(min-tablet) {
      grid-column: 2 / 7;
      grid-row: 1;

      z-index: 2;
      width: vw(1440, 570);
      height: vw(1440, 380);
    }
  }

  &.is-grid &__img-wrap:nth-of-type(2) {
    grid-column: 6 / -2;

    height: vw(360, 175);

    @include media(min-tablet) {
      grid-column: 6 / -2;
      grid-row: 1;

      width: vw(1440, 720);
      height: vw(1440, 450);
    }
  }

  &.is-grid &__img-wrap:nth-of-type(3) {
    grid-column: 3 / 10;

    height: vw(360, 130);

    @include media(min-tablet) {
      grid-column: 3 / 10;

      width: vw(1440, 840);
      height: vw(1440, 450);
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;

    z-index: 99;

    width: 100vw;
    height: 100vh;

    visibility: hidden;
    opacity: 0;
  }

  &__close-mask {
    position: absolute;
    left: 50%;

    transform: translateX(-50%);

    @include media(min-tablet) {
      top: vw(1440, 36);
    }
  }

  &__close {
    color: var(--color-white);
    text-transform: uppercase;

    @include media(min-tablet) {
      top: vw(1440, 36);

      font-size: vw(1440, 12);
      letter-spacing: spacing(170);
    }
  }

  /* Double 1/2 */
  &.is-double &__container {
    height: vw(360, 540);

    @include media(min-tablet) {
      height: vw(1440, 1137);
    }
  }

  &.is-double &__col {
    width: 100%;

    overflow: hidden;

    // &:nth-of-type(1) {
    //   .img {
    //     transform-origin: bottom left;
    //   }
    // }

    // &:nth-of-type(2) {
    //   .img {
    //     transform-origin: bottom right;
    //   }
    // }
  }

  &.is-double &__img-wrap {
    overflow: hidden;
  }

  &.is-double &__caption {
    display: inline-block;
    color: var(--color-black);
  }

  /* Dark theme (style-lint fix) */
  body.is-dark &.is-scale,
  body.is-dark &.is-double {
    .image__caption {
      color: var(--color-white);
    }
  }

  /* Double 1/2 */
  &.is-double &__col:nth-of-type(1) {
    position: absolute;

    @include media(max-tablet) {
      justify-self: flex-end;

      grid-column: 1 / 10;

      width: calc(100% - var(--spacing-15));
    }

    @include media(min-tablet) {
      grid-column: 1 / 9;

      top: vw(1440, 210);

      margin-left: vw(1440, 55);

      width: calc(100% - #{vw(1440, 60)});
    }
  }

  &.is-double &__col:nth-of-type(1) &__caption {
    @include media(max-tablet) {
      position: absolute;

      top: calc(#{vw(360, 10)} * -1);
      right: calc(#{vw(360, 15)} * -1);

      transform: rotate(-90deg);
      transform-origin: right;
    }

    @include media(min-tablet) {
      margin-top: var(--spacing-30);
    }
  }

  &.is-double &__col:nth-of-type(2) {
    grid-column: 6 / 13;

    position: absolute;

    font-size: vw(360, 14);

    @include media(max-tablet) {
      bottom: 0;
    }

    @include media(min-tablet) {
      grid-column: 7 / 13;

      font-size: vw(1440, 23);
    }
  }

  &.is-double &__col:nth-of-type(2) &__img-wrap {
    width: calc(100% - #{vw(360, 15)});

    @include media(min-tablet) {
      margin-left: vw(1440, 75);
      width: calc(100% - #{vw(1440, 135)});
    }
  }

  &.is-double &__col:nth-of-type(2) &__caption {
    font-size: vw(360, 14);

    @include media(max-tablet) {
      margin-top: var(--spacing-10);

      width: vw(360, 160);
    }

    @include media(min-tablet) {
      position: absolute;
      right: var(--spacing-20);
      bottom: 0;

      font-size: vw(1440, 14);

      writing-mode: vertical-lr;

      transform: rotate(180deg);
    }
  }
}
