.overlay-grid {
  &__wrap {
    grid-column: 2 / 12;

    @include media(max-tablet) {
      display: flex;
      flex-direction: column;
      row-gap: vw(360, 20);
    }

    @include media(min-tablet) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: vw(1440, 24);
      row-gap: vw(1440, 40);
    }

    span {
      text-transform: uppercase;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;

    @include media(min-tablet) {
      row-gap: vw(1440, 20);

      cursor: pointer;
    }

    img {
      transition: scale 0.67s var(--ease-in-out);
    }

    &:hover {
      img {
        scale: 1.03;
      }
    }
  }

  &__overlay {
    position: fixed;
    top: 50%;
    left: 50%;

    z-index: 99;

    padding: vw(360, 60) vw(360, 30);

    width: 100%;

    pointer-events: none;
    color: var(--color-white);

    background: var(--color-black);

    opacity: 0;
    transform: translate(-50%, -50%);

    @include media(max-tablet) {

      height: 100%;
      overflow: hidden;
      overflow-y: scroll;
    }

    @include media(min-tablet) {
      padding: vw(1440, 40) vw(1440, 60);
      width: vw(1440, 1000);
    }

    &.is-active {
      pointer-events: all;
    }
  }

  &__overlay-close {
    position: absolute;
    top: vw(360, 20);
    right: vw(360, 20);

    @include media(min-tablet) {
      top: vw(1440, 60);
      right: vw(1440, 60);
    }

    svg {
      width: vw(360, 22);
      height: vw(360, 22);

      @include media(min-tablet) {
        width: vw(1440, 22);
        height: vw(1440, 22);
      }
    }
  }

  &__overlay-content {
    display: flex;
    flex-direction: column;

    @include media(min-tablet) {
      row-gap: vw(1440, 40);

      width: vw(1440, 600);
    }
  }

  &__overlay-heading {
    color: var(--color-white);
  }

  &__overlay-images {
    display: flex;
    margin-top: vw(360, 30);

    @include media(max-tablet) {
      column-gap: vw(360, 20);
      width: 100%;

      overflow-x: scroll;

      scroll-snap-type: x mandatory;
    }

    @include media(min-tablet) {
      column-gap: vw(1440, 34);

      margin-top: vw(1440, 60);
    }
  }

  &__overlay-image {
    width: vw(360, 228);
    height: vw(360, 238);

    @include media(max-tablet) {
      flex-shrink: 0;

      scroll-snap-align: center;

      scroll-snap-stop: always;
    }

    @include media(min-tablet) {
      --aspect-ratio: 1.5143 !important;

      width: vw(1440, 424);
      height: vw(1440, 280);
    }
  }
}
