.faq {
  &__heading {
    grid-column: 2 / -2;

    @include media(min-tablet) {
      grid-column: 2 / 6;
    }
  }

  &__list {
    grid-column: 2 / -2;

    margin-top: var(--spacing-45);

    @include media(min-tablet) {
      grid-column: 3 / -1;

      padding-right: var(--spacing-60);
    }
  }

  &__item {
    padding: var(--spacing-30) 0;

    cursor: pointer;

    border-top: 1px solid var(--color-black-20);

    &:last-child {
      border-bottom: 1px solid var(--color-black-20);
    }
  }

  body.is-dark & {
    &__item {
      border-color: var(--color-white);
    }

    &__icon {
      fill: var(--color-white);
    }
  }

  &__item-question {
    position: relative;
    padding-right: var(--col-1);

    @include media(min-tablet) {
      padding-right: var(--col-3);

      font-size: vw(1440, 20);
      line-height: vw(1440, 25);
    }
  }

  &__item-anwser {
    padding-right: var(--col-1);

    height: 0;
    overflow: hidden;

    @include media(min-tablet) {
      padding-right: var(--col-3);
    }
  }

  &__icon {
    position: absolute;
    top: vw(360, 5);
    right: 0;

    width: var(--spacing-15);
    height: var(--spacing-15);

    fill: var(--color-black);

    @include media(min-tablet) {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
