.virtual-tour {
  &__iframe {
    grid-column: 1 / 13;
    position: relative;
    z-index: 1;

    margin: vw(360, 15);
    height: vw(360, 200);

    @include media(min-tablet) {
      margin: vw(1440, 60);
      height: vw(1440, 600);
    }
  }
}
