.error {
  position: relative;
  width: 100%;
  height: 100vh;

  &__content {
    position: absolute;
    bottom: var(--spacing-60);
    left: 50%;

    text-align: center;

    text-transform: uppercase;

    color: var(--color-grey-dark);

    @include media(max-tablet) {
      top: 50%;

      width: var(--col-10);

      transform: translate(-50%, -50%);
    }

    @include media(min-tablet) {
      bottom: var(--spacing-120);
      transform: translate(-50%, 0);
    }
  }

  &__heading {
    margin-bottom: var(--spacing-30);

    @include media(min-tablet) {
      margin-bottom: var(--spacing-120);
    }
  }

  &__link {
    display: block;

    margin-top: var(--spacing-30);

    font-size: vw(360, 20);

    color: var(--color-white);

    text-transform: initial;

    @include media(min-tablet) {
      font-size: vw(1440, 20);
    }
  }
}
