body {
  letter-spacing: spacing(0);
  font-family: var(--font-f-messina);
  font-size: var(--font-s-body);
  line-height: var(--lh-body);
  color: var(--color-black);
}

.heading {
  letter-spacing: spacing(0);
  color: var(--color-black);
  font-family: var(--font-f-boogy);

  &.is-xl {
    font-size: var(--font-s-heading-xl);
    line-height: var(--lh-heading-xl);
  }

  &.is-l {
    font-size: var(--font-s-heading-l);
    line-height: var(--lh-heading-l);
  }

  &.is-lg {
    font-size: var(--font-s-heading-lg);
    line-height: var(--lh-heading-lg);
  }

  &.is-md {
    line-height: var(--lh-heading-md);
    font-size: var(--font-s-heading-md);

    &--boogy {
      font-family: var(--font-f-boogy-medium);
    }
  }

  &.is-rg {
    font-size: var(--font-s-heading-rg);
    line-height: var(--lh-heading-rg);
  }

  &.is-rgsm {
    font-size: var(--font-s-heading-rgsm);
    line-height: var(--lh-heading-rgsm);
  }

  &.is-sm {
    font-size: var(--font-s-heading-sm);
    line-height: var(--lh-heading-sm);
    font-family: var(--font-f-messina);
  }
}

.intro {
  color: var(--color-black);
  letter-spacing: spacing(0);
  font-size: var(--font-s-intro);
  line-height: var(--lh-intro);
}

.chapeau {
  letter-spacing: spacing(170);
  font-size: var(--font-s-chapeau);
  line-height: var(--lh-chapeau);
  text-transform: uppercase;
}

.caption {
  letter-spacing: spacing(0);
  font-size: var(--font-s-caption);
  line-height: var(--lh-caption);
}

body.is-dark .heading,
body.is-dark .chapeau {
  color: var(--color-greige);
}

body.is-dark {
  color: var(--color-white);
}
