.numbers {
  &__chapeau,
  &__heading,
  &__body,
  &__button {
    grid-column: 2 / -2;
  }

  &__body {
    @include media(min-tablet) {
      grid-column: 2 / span 4;
    }
  }

  &__button {
    @include media(min-tablet) {
      align-self: end;

      width: fit-content;
    }
  }

  &__chapeau + &__heading {
    margin-top: var(--spacing-20);
  }

  &__heading + &__body {
    margin-top: var(--spacing-30);
  }

  &__items {
    grid-column: 2 / 12;

    margin-top: var(--spacing-90);

    @include media(min-tablet) {
      grid-column: 2 / 13;

      padding-right: var(--spacing-60);
    }
  }

  &__item {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    align-items: center;

    position: relative;
    padding: var(--spacing-30) 0;

    visibility: hidden;
    opacity: 0;

    border-bottom: 1px solid var(--color-black-20);

    &:first-of-type {
      border-top: 1px solid var(--color-black-20);
    }
  }

  &__image {
    grid-column: 7 / -1;
    grid-row: 1 / 3;

    height: 100%;

    object-fit: cover;

    @include media(min-tablet) {
      display: none;
    }
  }

  &__number-wrapper {
    grid-column: 1 / span 5;

    overflow: hidden;
    font-family: var(--font-f-boogy);

    font-size: vw(360, 50);
    line-height: vw(360, 50);

    @include media(min-tablet) {
      grid-column: 1 / span 2;

      font-size: vw(1440, 70);
      line-height: vw(1440, 85);
    }
  }

  &__number {
    display: inline-flex;
    flex-direction: column;
    row-gap: var(--spacing-30);
  }

  &__title {
    grid-column: 1 / span 5;

    @include media(min-tablet) {
      grid-column: 3 / -1;

      font-size: vw(1440, 32);
      line-height: vw(1440, 48);
    }
  }

  &__img {
    position: absolute;

    z-index: 2;

    visibility: hidden;

    overflow: hidden;

    pointer-events: none;
    opacity: 0;

    @include media(min-tablet) {
      width: vw(1440, 380);
      height: vw(1440, 270);
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      z-index: 0;

      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }

  &__img-src {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;

    transform: scale(1.5);

    object-fit: cover;
  }

  &__img-mask {
    display: block;

    position: absolute;
    bottom: 0;

    z-index: 0;

    width: 100%;
    height: 100%;

    visibility: hidden;

    overflow: hidden;

    opacity: 0;

    transform-origin: bottom;

    will-change: transform;
  }

  &__img-wrap {
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;

    transform-origin: bottom;

    will-change: transform;
  }

  @media (hover: hover) {
    &__items &__item {
      transition: opacity 0.4s cubic-bezier(0.51, 0, 0.37, 1);
      will-change: opacity;
    }

    &__items:hover &__item {
      opacity: 0.3 !important;
    }

    &__items &__item:hover {
      opacity: 1 !important;
    }
  }
}
