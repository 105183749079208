.footer {
  position: relative;
  padding: var(--spacing-60) 0 var(--spacing-30);

  background-color: var(--color-black);

  &__col {
    grid-column: 2 / 12;

    @include media(max-tablet) {
      &--right {
        margin-top: var(--spacing-30);
      }
    }

    @include media(min-tablet) {
      &--left {
        grid-column: 3 / 6;
      }

      &--right {
        grid-column: 8 / 11;
      }
    }
  }

  &__col--right &__top-menu,
  &__col--right &__bottom-menu {
    @include media(min-tablet) {
      justify-content: flex-end;
    }
  }

  &__top-menu {
    @include media(max-tablet) {
      text-align: center;
    }

    @include media(min-tablet) {
      padding-bottom: var(--spacing-30);
      border-bottom: 1px solid var(--color-white-20);
    }
  }

  &__bottom-menu {
    @include media(max-tablet) {
      display: none;
    }

    @include media(min-tablet) {
      display: flex;
      column-gap: var(--spacing-30);

      padding-top: var(--spacing-30);
    }
  }

  &__logo {
    @include media(max-tablet) {
      grid-column: 2 / 12;
      grid-row: 1;

      margin-bottom: var(--spacing-60);
    }

    @include media(min-tablet) {
      grid-column: 6 / 8;
    }

    > * {
      color: var(--color-white);
    }
  }

  &__list {
    @include media(max-tablet) {
      flex-direction: column;
      row-gap: var(--spacing-30);
    }

    display: inline-flex;
    column-gap: var(--spacing-60);
  }

  &__list--mobile {
    display: none;

    flex-direction: column;
    grid-column: 2 / 12;
    row-gap: var(--spacing-30);

    margin-top: var(--spacing-30);

    text-align: center;
  }

  &__item {
    @include media(max-tablet) {
      display: flex;
      justify-content: space-evenly;
    }

    @include media(min-tablet) {
      position: relative;

      &:first-of-type::after {
        content: '';

        position: absolute;
        top: 50%;
        right: calc(var(--spacing-30) * -1);

        width: 1px;
        height: vw(1440, 7);

        background-color: var(--color-white);

        transform: translateY(-50%);
      }
    }
  }

  &__link {
    font-size: vw(360, 16);

    text-transform: uppercase;
    letter-spacing: spacing(170);
    color: var(--color-white);

    @include media(min-tablet) {
      font-size: vw(1440, 16);
    }
  }

  &__bottom {
    grid-column: 3 / 11;

    text-align: center;

    color: var(--color-grey-dark);
  }

  &__bottom-top {
    display: flex;
    flex-direction: column;

    row-gap: var(--spacing-30);

    @include media(min-tablet) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      flex-direction: row;

      justify-content: space-between;
    }
  }

  &__socials {
    @include media(min-tablet) {
      text-align: left;
    }
  }

  &__socials-list {
    @include media(max-tablet) {
      justify-content: center;
    }

    display: flex;

    column-gap: var(--spacing-15);
  }

  &__social-icon {
    .icon {
      height: vw(360, 16);

      fill: var(--color-white);

      @include media(min-tablet) {
        height: vw(1440, 16);
      }
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    text-align: left;

    gap: var(--spacing-30);
  }

  &__logos {
    @include media(max-tablet) {
      order: 3;
    }

    display: flex;
    align-items: center;

    column-gap: var(--spacing-60);

    @include media(max-tablet) {
      flex-direction: column;

      gap: var(--spacing-30);
    }
  }

  &__svg-logo {
    fill: var(--color-white);

    &--eefje {
      width: vw(360, 79);
      height: vw(360, 52.48);

      @include media(min-tablet) {
        width: vw(1440, 79);
        height: vw(1440, 52.48);
      }
    }

    &--broersma {
      width: vw(360, 101);
      height: vw(360, 39);

      @include media(min-tablet) {
        width: vw(1440, 101);
        height: vw(1440, 39);
      }
    }
  }

  &__copyright {
    display: flex;
    flex-direction: column;

    color: var(--color-white);

    span {
      color: var(--color-grey-dark);
    }

    @include media(min-tablet) {
      align-items: flex-end;
      font-size: vw(1440, 14);
    }
  }

  &__copyright-link {
    color: var(--color-white);
  }

  &__slogan {
    display: block;

    margin-top: var(--spacing-60);

    a {

      text-decoration: underline;
      color: var(--color-grey-dark);
    }
  }
}
