.register-cta {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: fixed;
  bottom: var(--spacing-10);
  left: 50%;

  z-index: 11;

  padding: var(--spacing-20);

  width: vw(360, 340);
  height: vw(360, 220);

  visibility: hidden;

  color: var(--color-pink);
  background: var(--color-black);

  opacity: 0;

  transform: translateX(-50%);

  @include media(min-tablet) {
    bottom: var(--spacing-30);
    left: var(--spacing-30);

    width: vw(1440, 320);
    height: vw(1440, 180);

    transform: inherit;
  }

  &__title {
    display: block;

    width: vw(360, 240);

    font-size: vw(360, 24);
    line-height: vw(360, 32);

    letter-spacing: spacing(-50);

    @include media(min-tablet) {
      width: vw(1440, 240);

      font-size: vw(1440, 24);
      line-height: vw(1440, 32);
    }
  }

  &__close-button {
    position: absolute;
    top: var(--spacing-20);
    right: var(--spacing-20);
  }

  &__svg {
    width: vw(360, 26.57);
    height: vw(360, 26.57);

    fill: none;
    stroke: #fff;

    @include media(min-tablet) {
      width: vw(1440, 26.57);
      height: vw(1440, 26.57);
    }
  }

  &__button {
    color: var(--color-white);
    text-transform: uppercase;
    text-decoration: underline;
  }

  &__open-button {
    position: fixed;
    bottom: var(--spacing-10);
    left: var(--spacing-10);

    z-index: 10;

    padding: var(--spacing-10) var(--spacing-20);

    font-size: vw(360, 15);
    line-height: vw(360, 15);

    text-transform: uppercase;

    color: var(--color-white);

    background: var(--color-black);
    opacity: 0;

    @include media(min-tablet) {
      bottom: var(--spacing-30);
      left: var(--spacing-30);

      font-size: vw(1440, 15);
      line-height: vw(1440, 15);
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
