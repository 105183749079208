.contact {
  &__content {
    display: flex;

    grid-column: 1 / 13;

    @include media(max-tablet) {
      flex-direction: column;
      row-gap: var(--spacing-60);
    }

    @include media(min-tablet) {
      grid-column: 6 / 13;

      column-gap: var(--spacing-120);

      padding-right: var(--spacing-60);
    }
  }

  &__col {
    row-gap: var(--spacing-30);

    @include media(max-tablet) {
      display: grid;

      grid-template-columns: repeat(12, 1fr);

      svg {
        grid-column: 1 / 6;
        grid-row: 3;
        align-self: flex-end;
        justify-self: center;
        max-width: vw(360, 110);
      }
    }

    @include media(min-tablet) {
      display: flex;
      flex-direction: column;

      width: 25%;

      svg {
        flex-grow: 1;
      }
    }
  }

  &__title,
  &__details {
    @include media(max-tablet) {
      grid-column: 6 / 12;
    }
  }

  &__link {
    text-decoration: underline;
    color: var(--color-white);
  }
}
