.register {
  width: 100%;
  color: var(--color-white);

  background: var(--color-black);

  @include media(min-tablet) {
    height: 100%;
  }

  &__logo {
    position: absolute;
    top: var(--spacing-60);
    left: var(--spacing-30);

    cursor: pointer;

    color: var(--color-white);

    @include media(min-tablet) {
      left: 50%;
      transform: translateX(-50%);
    }

    svg {
      fill: var(--color-white);
    }
  }

  &__form {
    display: flex;
    flex-direction: column;

    row-gap: var(--spacing-15);

    position: relative;

    margin: 0 auto;

    padding: var(--spacing-180) 0 var(--spacing-120);

    width: var(--col-10);

    @include media(min-tablet) {
      position: absolute;
      top: 50%;
      left: 50%;

      padding: 0;

      width: var(--col-8);

      transform: translate(-50%, -50%);
    }
  }

  &__form-wrap {
    display: flex;
    flex-direction: column;
    row-gap: var(--spacing-15);

    @include media(min-tablet) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);

      column-gap: vw(1440, 60);
      row-gap: vw(1440, 30);
    }
  }

  &__form-buttons {
    display: flex;

    flex-wrap: wrap;

    column-gap: var(--spacing-30);
    row-gap: var(--spacing-15);

    @include media(min-tablet) {
      column-gap: var(--spacing-60);
    }

    span {
      width: 100%;
    }
  }

  &__form-button {
    display: flex;
    align-items: center;

    column-gap: var(--spacing-10);

    flex-basis: 1;

    position: relative;

    color: white;

    line-height: vw(360, 23);

    @include media(min-tablet) {
      line-height: vw(1440, 23);
    }

    &.active {
      .register__form-check-icon {
        visibility: visible;
      }
    }
  }

  &__form-checkbox {
    width: vw(360, 23);
    height: vw(360, 23);

    pointer-events: none;

    border: 1px solid white;

    @include media(min-tablet) {
      width: vw(1440, 23);
      height: vw(1440, 23);
    }
  }

  &__form-check-icon {

    width: vw(360, 20);
    height: vw(360, 10);
    visibility: hidden;

    fill: var(--color-white);

    @include media(min-tablet) {
      width: vw(1440, 20);
      height: vw(1440, 10);
    }
  }

  &__label {
    display: flex;
    flex-direction: column;

    @include media(min-tablet) {
      row-gap: vw(1440, 5);
    }

    &--firstname {
      grid-column: 1;
      grid-row: 1;
    }

    &--lastname {
      grid-column: 2;
      grid-row: 1;
    }

    &--email {
      grid-column: 1;
      grid-row: 2;
    }

    &--phone {
      grid-column: 2;
      grid-row: 2;
    }

    // &--message {
    //   grid-column: 2;
    //   grid-row: 2 / 4;
    // }
  }

  &__field {
    display: block;

    padding: 0 var(--spacing-15);

    width: 100%;

    line-height: vw(360, 50);

    color: var(--color-white);

    background: none;

    border: 2px solid #ffffff26;

    border-radius: 5px;

    @include media(min-tablet) {
      line-height: vw(1440, 45);
    }

    &:focus {
      border-color: #e67e1e;
    }

    &.error {
      height: auto;
      border-color: red;
    }

    // &--textarea {
    //   height: 100%;
    // }
  }

  &__disclaimer {
    @include media(max-tablet) {
      display: block;

      margin: 0 auto;

      padding-bottom: var(--spacing-30);

      width: var(--col-10);
    }

    font-size: vw(360, 12);

    @include media(min-tablet) {
      position: absolute;
      right: vw(1440, 30);
      bottom: vw(1440, 30);

      font-size: vw(1440, 12);
    }
  }

  &__submit-button {
    align-self: flex-end;
    margin-top: var(--spacing-30);
  }

  &__rental-link {
    align-self: center;

    text-decoration: underline;

    text-transform: uppercase;
    color: var(--color-white);
  }

  &__response {
    position: absolute;

    bottom: vw(360, 60);

    right: 0;

    @include media(min-tablet) {
      bottom: calc(vw(1440, 50) * -1);
    }
  }

  &__contact--mobile {
    @include media(max-tablet) {
      display: flex;

      column-gap: var(--spacing-10);

      position: absolute;
      top: var(--spacing-60);
      right: var(--spacing-30);

      text-transform: uppercase;

      color: var(--color-white);

      span {
        height: vw(360, 16);
        line-height: vw(360, 16);
      }
    }

    display: none;
  }

  &__contact {
    @include media(max-tablet) {
      display: none;
    }

    display: flex;
    column-gap: var(--spacing-15);

    position: absolute;

    bottom: var(--spacing-30);
    left: var(--spacing-30);

    height: vw(1440, 16);

    font-size: vw(1440, 16);
    line-height: vw(1440, 16);

    text-transform: uppercase;
    letter-spacing: spacing(170);

    color: var(--color-white);

    span {
      height: vw(1440, 16);
      line-height: vw(1440, 16);
    }
  }

  &__contact-icon {
    width: vw(360, 10.5);
    height: vw(360, 10.5);

    fill: var(--color-white);

    @include media(min-tablet) {
      width: vw(1440, 10.5);
      height: vw(1440, 10.5);
    }
  }

  &__success {
    position: absolute;
    top: 50%;
    left: 50%;

    visibility: hidden;

    font-size: vw(360, 40);
    line-height: vw(360, 49);

    text-align: center;

    letter-spacing: spacing(-80);

    opacity: 0;

    transform: translate(-50%, -50%);

    @include media(min-tablet) {
      font-size: vw(1440, 50);
      line-height: vw(1440, 59);
    }
  }

  &__link {
    margin-top: var(--spacing-30);

    font-size: vw(360, 20);

    letter-spacing: -0.08em;

    color: var(--color-white);

    @include media(min-tablet) {
      font-size: vw(1440, 20);
    }
  }

  &__loader {
    display: none;

    width: vw(360, 20);
    height: vw(360, 20);

    @include media(min-tablet) {
      width: vw(1440, 20);
      height: vw(1440, 20);
    }
  }
}
