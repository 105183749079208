.nav {
  display: flex;
  align-items: center;

  justify-content: space-between;

  position: fixed;

  z-index: 2;
  margin: var(--spacing-15) 0;

  padding: 0 var(--spacing-15);

  width: 100%;

  height: vw(360, 109);

  .menu__lang-switch {
    &:not(&-mobile) {
      @include media(max-tablet) {
        display: none;
      }
    }

    position: absolute;
    top: vw(1440, 73);
    left: vw(1440, 80);

    .menu__lang-switch-link,
    .menu__lang-switch-item {
      color: var(--color-black);
    }

    .menu__lang-icon {
      stroke: var(--color-black);
    }
  }

  @include media(min-tablet) {
    justify-content: center;

    margin: 0;

    padding: var(--spacing-30) 0 0 0;

    height: vw(1440, 109);

    pointer-events: none;

    box-sizing: content-box;
  }

  &__menu {
    @include media(max-tablet) {
      justify-content: space-between;

      align-items: center;

      width: 100%;
    }

    display: flex;
  }

  &__list {
    display: none;

    @include media(min-tablet) {
      display: flex;

      align-items: center;
    }
  }

  &__link {
    display: block;

    pointer-events: all;

    font-size: vw(1440, 16);
    line-height: vw(1440, 16);

    text-transform: uppercase;
    letter-spacing: spacing(170);

    will-change: transform;
  }

  &__item {
    display: none;

    @include media(min-tablet) {
      display: inline-block;

      position: relative;

      padding: 0 var(--spacing-20);

      height: vw(1440, 12);

      visibility: hidden;
      opacity: 0;

      &::after {
        content: '';
        display: block;
        position: absolute;

        top: 50%;
        right: 0;

        width: vw(1440, 1);
        height: vw(1440, 7);

        background: var(--color-white);
        transform: translateY(-50%);
      }
    }
  }

  &__list--right &__item {
    padding: 0 var(--spacing-20);

    &::after {
      right: unset;
      left: 0;
    }
  }

  &__logo-link {
    pointer-events: all;

    will-change: transform;

    @include media(min-tablet) {
      margin: vw(1440, 15) var(--spacing-40) 0;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    width: vw(360, 109);
    height: vw(360, 109);

    visibility: hidden;

    pointer-events: all;

    opacity: 0;

    border-radius: 50%;

    &--hidden {
      visibility: hidden !important;
      opacity: 0 !important;
    }

    @include media(min-tablet) {
      position: absolute;
      top: var(--spacing-30);
      right: var(--spacing-30);

      width: vw(1440, 109);
      height: vw(1440, 109);
    }

    > * {
      position: absolute;
      top: 50%;
      left: 50%;

      pointer-events: none;

      transform: translate(-50%, -50%);
    }
  }

  &__orange-circle {
    display: block;

    z-index: 1;

    width: vw(360, 109);
    height: vw(360, 109);

    background: linear-gradient(60deg, rgba(247, 175, 95, 100%) 0%, rgba(213, 157, 106, 100%) 100%);

    border-radius: 50%;

    will-change: transform;

    @include media(min-tablet) {
      width: vw(1440, 108);
      height: vw(1440, 108);

      transform: translate(-50%, -50%) scale(0);
    }
  }

  &__outer-circle {
    width: vw(360, 108);
    height: vw(360, 108);

    overflow: visible;

    fill: none;
    stroke: var(--color-black-20);
    stroke-width: 1.5px;

    @include media(min-tablet) {
      width: vw(1440, 108);
      height: vw(1440, 108);
    }
  }

  &__burger-icon {
    z-index: 3;

    width: vw(360, 32);
    height: vw(360, 12);

    fill: none;
    stroke: var(--color-black);
    stroke-width: 2;

    @include media(min-tablet) {
      width: vw(1440, 32);
      height: vw(1440, 12);
    }

    path {
      display: block;

      will-change: transform;

      &:nth-of-type(4),
      &:nth-of-type(5),
      &:nth-of-type(6) {
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  &__mobile-list {
    display: flex;
    column-gap: var(--spacing-30);

    @include media(min-tablet) {
      display: none;
    }
  }

  &__mobile-link {
    color: var(--color-white);

    font-size: vw(360, 12);
    line-height: vw(360, 12);

    text-transform: uppercase;
    letter-spacing: spacing(170);
  }

  body.is-dark & {
    &__logo {
      > * {
        fill: var(--color-white);
      }
    }

    &__link {
      color: var(--color-white);
    }

    &__outer-circle {
      stroke: var(--color-white);
    }

    &__orange-circle {
      background: var(--color-greige);
    }

    &__burger-icon {
      stroke: var(--color-black);

      @include media(min-tablet) {
        stroke: var(--color-white);
      }
    }
  }

  &.is-dark &__logo {
    color: var(--color-white);
  }

  &.is-dark &__link {
    color: var(--color-white);
  }

  &.is-dark &__outer-circle {
    stroke: var(--color-white-20);
  }

  &.is-dark &__burger-icon {
    @include media(min-tablet) {
      stroke: var(--color-white);
    }
  }

  &.is-dark .menu__lang-switch-link,
  &.is-dark .menu__lang-switch-item {
    color: var(--color-white);
  }

  &.is-dark .menu__lang-icon {
    stroke: var(--color-white);
  }
}
