.header {
  display: block;

  position: relative;

  width: 100%;

  overflow: hidden;

  &__chapeau,
  &__heading,
  &__richtext {
    display: block;

    visibility: hidden;

    opacity: 0;
  }

  &__chapeau {
    overflow: hidden;

    font-size: vw(360, 14) !important;
    line-height: vw(360, 14) !important;

    @include media(min-tablet) {
      font-size: vw(1440, 14) !important;
      line-height: vw(1440, 14) !important;
    }
  }

  &__chapeau + &__heading {
    margin-top: var(--spacing-30);
  }

  &__heading-wrap {
    // overflow: hidden;
    clip-path: inset(-10px -100%);
  }

  // fluid
  &.is-fluid {
    height: vw(360, 930);

    @include media(min-tablet) {
      height: vw(1440, 750);
    }
  }

  &.is-fluid .container {
    height: 100%;

    @include media(min-tablet) {
      height: 100%;
    }
  }

  &.is-fluid &__content {
    grid-column: 2 / 12;
    z-index: 1;

    @include media(max-tablet) {
      margin-top: vw(360, 228);
    }

    @include media(min-tablet) {
      align-self: flex-end;
      grid-column: 2 / 12;

      padding-bottom: vw(1440, 120);
    }
  }

  &.is-fluid &__chapeau,
  &.is-fluid &__heading {
    color: var(--color-white);
  }

  &.is-fluid &__img-wrap {
    position: absolute;
    top: 0;

    z-index: -1;

    width: 100%;

    @include media(min-tablet) {
      --aspect-ratio: 1.2643 !important;
    }
  }

  &.is-fluid &__overlay {
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;

    visibility: hidden;

    background-color: var(--color-black-20);

    opacity: 0;

    &::before {
      content: '';

      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 60%;

      background: linear-gradient(0deg, rgba(255, 255, 255, 0%) 0%, rgba(0, 0, 0, 20%) 100%);
    }
  }

  // Contained
  &.is-contained &__content {

    grid-column: 2 / 12;
    z-index: 1;

    margin-top: vw(360, 228);

    text-align: center;

    @include media(min-tablet) {
      margin-top: vw(1440, 300);
    }
  }

  &.is-contained &__img-wrap {
    grid-column: 2 / 12;

    margin-top: var(--spacing-30);

    @include media(min-tablet) {
      grid-column: 3 / 11;

      margin-top: calc(#{vw(1440, 60)} * -1);
    }
  }

  // HOME
  &.is-home {
    padding: vw(360, 228) 0;

    height: vw(360, 1235);

    @include media(min-tablet) {
      padding: vw(1440, 300) 0;

      height: vw(1440, 1642);
    }
  }

  &.is-home &__img-wrap {
    position: absolute;
    top: 0;

    z-index: -1;

    width: 100%;

    @include media(min-tablet) {
      --aspect-ratio: 0.877 !important;
    }

    .img {
      transform: translateY(-20%) scale(1.25);
    }
  }

  &.is-home &__chapeau,
  &.is-home &__heading {
    color: var(--color-white);
  }

  &.is-home &__content {
    margin: 0 auto;

    width: var(--col-10);

    text-align: center;

    @include media(min-tablet) {
      margin: 0 auto;

      width: var(--col-6);
    }
  }

  &.is-home &__richtext {
    margin: 0 auto;

    @include media(min-tablet) {
      width: var(--col-4);
    }
  }

  &.is-home &__button {
    justify-content: center;
  }

  // Image/Video
  &.is-image-video {
    height: vw(360, 1235);

    @include media(min-tablet) {
      min-height: vw(1440, 900);
      height: auto;
    }
  }

  &.is-image-video &__img-wrap {
    position: absolute;

    z-index: -1;

    width: 100%;
    height: 100%;

    @include media(min-tablet) {
      width: 100%;
      height: 100%;

      --aspect-ratio: 0.6614 !important;
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .img {
      transform: translateY(-20%) scale(1.25);
    }
  }

  &.is-image-video &__content {
    padding-top: vw(360, 200);

    margin: 0 auto;

    @include media(min-tablet) {
      padding: vw(1440, 200) 0 vw(1440, 60);
    }
  }

  &.is-image-video &__heading {
    grid-column: 2 / 12;

    color: var(--color-white);

    text-align: center;

    @include media(min-tablet) {
      margin-top: var(--spacing-40);
    }
  }

  &.is-image-video &__buttons {
    @include media(min-tablet) {
      display: flex;
      justify-self: center;
      justify-content: center;

      column-gap: var(--spacing-60);
    }
  }

  &.is-image-video &__sub-content {
    grid-column: 2 / 12;

    margin-top: var(--spacing-30);

    text-align: center;

    color: var(--color-white);

    @include media(min-tablet) {
      grid-column: 3 / 11;

      margin-top: var(--spacing-40);
    }
  }

  &.is-image-video &__play-button {
    display: inline-flex;
    align-items: center;

    column-gap: var(--spacing-30);

    margin-top: var(--spacing-30);
    visibility: hidden;

    text-decoration: underline;

    text-transform: uppercase;

    color: var(--color-white);

    opacity: 0;
  }

  &.is-image-video &__button-play-icon {
    z-index: 2;

    width: vw(360, 11);
    height: vw(360, 10);

    fill: var(--color-white);

    @include media(min-tablet) {
      width: vw(1440, 11);
      height: vw(1440, 10);
    }
  }

  &.is-image-video &__bottom {
    @include media(max-tablet) {
      margin-top: var(--spacing-30);
    }

    @include media(min-tablet) {
      position: absolute;
      bottom: var(--spacing-60);
    }
  }

  &.is-image-video &__sub-heading {
    grid-column: 2 / 12;

    color: var(--color-white);

    @include media(min-tablet) {
      grid-column: 3 / 11;
    }
  }

  &.is-image-video &__button {
    display: flex;
    align-items: center;
    justify-self: flex-end;
    grid-column: 9 / 11;

    column-gap: var(--spacing-30);
    margin-top: var(--spacing-30);

    text-transform: uppercase;

    color: var(--color-white);

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;

      width: vw(360, 57.2);
      height: vw(360, 57.2);

      @include media(min-tablet) {
        width: vw(1440, 57.2);
        height: vw(1440, 57.2);
      }
    }

    &__background {
      position: absolute;

      z-index: 1;

      width: vw(360, 57.2);
      height: vw(360, 57.2);

      background: linear-gradient(60deg, rgb(247, 175, 95) 0%, rgb(213, 157, 106) 100%);

      border-radius: 50%;

      @include media(min-tablet) {
        width: vw(1440, 57.2);
        height: vw(1440, 57.2);
      }
    }

    &__ring {
      position: absolute;

      width: vw(360, 78);
      height: vw(360, 78);

      border: 1px solid var(--color-white);
      border-radius: 50%;

      transform: scale(0);

      @include media(min-tablet) {
        width: vw(1440, 78);
        height: vw(1440, 78);
      }
    }

    &__arrow {
      z-index: 2;
      fill: var(--color-white);
    }
  }

  &.is-image-video.is-light &__heading,
  &.is-image-video.is-light &__sub-heading,
  &.is-image-video.is-light &__sub-content,
  &.is-image-video.is-light &__play-button,
  &.is-image-video.is-light &__button {
    color: var(--color-black);
  }

  &.is-image-video.is-light &__button-play-icon {
    fill: var(--color-black);
  }

  &.is-image-video &__overlay,
  &.is-home &__overlay {
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;

    visibility: hidden;

    opacity: 0;

    &::before {
      content: '';

      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background: linear-gradient(0deg, rgba(255, 255, 255, 0%) 0%, #000000bf  100%);

      @include media(min-tablet) {
        height: 60%;

        background: linear-gradient(0deg, rgba(255, 255, 255, 0%) 0%, #0009  100%);
      }
    }
  }

  &.is-home &__heading + &__richtext {
    margin-top: var(--spacing-30);
  }

  // Contact
  &.is-contact {
    padding: vw(360, 285) var(--col-1) var(--spacing-180);

    @include media(min-tablet) {
      padding: vw(1440, 285) var(--col-2) 0 var(--spacing-180);
    }
  }

  &.is-contact &__heading {
    color: var(--color-white);
  }

  &[data-theme='light'].is-contact &__heading {
    color: var(--color-black);
  }
}
