.carousel {
  &__chapeau,
  &__heading,
  &__body,
  &__button {
    grid-column: 2 / -2;
  }

  &__body {
    @include media(min-tablet) {
      grid-column: 2 / span 4;
    }
  }

  &__button {
    @include media(max-tablet) {
      margin-top: 0;
    }

    @include media(min-tablet) {
      align-self: end;

      width: fit-content;
    }
  }

  &__heading + &__body {
    margin-top: var(--spacing-30);
  }

  &__buttons {
    .carousel__button {
      margin-top: 0;
    }

    @include media(max-tablet) {
      flex-direction: column-reverse;

      row-gap: var(--spacing-30);
    }

    display: flex;
    flex-direction: row-reverse;

    justify-content: space-between;

    margin-top: var(--spacing-30);

    padding: 0 var(--col-1);

    width: 100%;
  }

  &__buttons + &__slider {
    margin-top: var(--spacing-90);

    @include media(min-tablet) {
      cursor: none;
    }
  }

  &__navigation {
    @include media(max-tablet) {
      display: none;
    }

    display: flex;
    column-gap: var(--spacing-30);
  }

  &__navigation-button {
    width: vw(360, 57.2);
    height: vw(360, 57.2);

    cursor: pointer;

    border: 1px solid var(--color-black);

    border-radius: 50%;

    @include media(min-tablet) {
      width: vw(1440, 57.2);
      height: vw(1440, 57.2);
    }

    &:first-of-type {
      svg {
        rotate: -180deg;
      }
    }
  }

  &__button-icon {
    pointer-events: none;

    fill: var(--color-black);
  }

  &__content {
    @include media(max-tablet) {
      grid-column: 2 / 12;
    }
  }

  &__chapeau + &__heading {
    margin-top: var(--spacing-20);
  }

  &__item {
    width: vw(360, 268);

    @include media(min-tablet) {
      width: vw(1440, 480);
    }
  }

  .img {
    pointer-events: none;
    user-select: none;
  }

  &.is-interactive &__wrap {
    display: flex;
    column-gap: var(--spacing-15);

    position: relative;

    padding: 0 var(--spacing-15);

    width: 100%;
    height: 100%;

    will-change: transform;

    @include media(min-tablet) {
      column-gap: var(--spacing-30);

      padding: 0 var(--spacing-30);
    }
  }

  &.is-interactive &__item {
    flex-shrink: 0;

    position: relative;

    width: vw(360, 269);
    height: vw(360, 269);

    @include media(min-tablet) {
      width: vw(1440, 481);
      height: vw(1440, 481);
    }
  }

  &.is-interactive &__overlay {
    position: absolute;
    z-index: 2;

    width: 100%;
    height: 100%;

    background: var(--color-black);

    opacity: 0.3;
  }

  &.is-interactive &__item-content {
    position: absolute;
    top: 50%;
    left: 50%;

    z-index: 2;

    text-align: center;

    color: var(--color-white);

    transform: translate(-50%, -50%);
  }

  &.is-interactive &__item-chapeau {
    text-transform: uppercase;

    @include media(min-tablet) {
      font-size: vw(1440, 14);
    }
  }

  &.is-interactive &__item-title {
    @include media(min-tablet) {
      font-size: vw(1440, 41);
      letter-spacing: spacing(-80);
      line-height: vw(1440, 55);
    }
  }

  &.is-interactive &__item-wrap {
    overflow: hidden;
  }

  @media (hover: hover) {
    &.is-interactive &__item &__overlay {
      transition: opacity 0.42s var(--ease-text-chapeau);
    }

    &.is-interactive &__item:hover &__overlay {
      opacity: 0.63;
    }

    &.is-interactive &__item .img {
      transition: transform 0.67s var(--ease-in-out);
      will-change: transform;
    }

    &.is-interactive &__item:hover .img {
      transform: scale(1.03);
    }

    &.is-interactive &__item &__chapeau-char,
    &.is-interactive &__item &__title-char {
      transform: translateY(100%);
      transition: transform 0.87s var(--ease-text-main);
      will-change: transform;
    }

    &.is-interactive &__item:hover &__chapeau-char,
    &.is-interactive &__item:hover &__title-char {
      transform: translateY(0%);

      @for $i from 1 through 50 {
        &:nth-child(#{$i}) {
          transition-delay: 0.01s * $i;
        }
      }
    }
  }

  &.is-static &__wrap {
    display: grid;

    grid-template-columns: repeat(var(--slides-total), vw(360, 268));
    grid-column-gap: var(--spacing-15);

    padding: 0 var(--spacing-15);

    @include media(min-tablet) {
      grid-template-columns: repeat(var(--slides-total), vw(1440, 480));
      grid-column-gap: var(--spacing-30);

      padding: 0 var(--spacing-30);
    }
  }

  &.is-static &__slide-button {
    align-self: flex-start !important;
  }

  &.is-static &__item > &__content {
    display: flex;
    flex-direction: column;

    row-gap: var(--spacing-15);

    margin-top: var(--spacing-15);

    @include media(min-tablet) {
      margin-top: var(--spacing-30);
    }
  }

  &.is-static &__item > &__content > &__heading {
    font-weight: bold;

    font-size: vw(360, 21);

    @include media(min-tablet) {
      font-size: vw(1440, 21);
    }
  }

  &.is-static &__caption {
    display: inline-block;

    user-select: none;
  }

  &--not-interactive &__container + &__slider {
    margin-top: var(--spacing-60) !important;

    @include media(min-tablet) {
      margin-top: var(--spacing-90) !important;
      cursor: initial !important;
    }
  }

  &--not-interactive &__item {
    @include media(min-tablet) {
      flex-shrink: 1 !important;
      width: 100% !important;
      height: 100% !important;
    }
  }

  &--not-interactive.is-static &__wrap {
    @include media(min-tablet) {
      display: flex !important;
    }
  }

  &--not-interactive &__button {
    @include media(max-tablet) {
      margin-top: var(--spacing-60) !important;
    }

    @include media(min-tablet) {
      align-self: flex-end !important;
    }
  }
}

body.is-dark .carousel {
  &__navigation-button {
    border-color: var(--color-white);
  }

  &__button-icon {
    fill: var(--color-white);
  }
}
