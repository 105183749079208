html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;

  overscroll-behavior-y: none;
}

body.is-dark {
  background: var(--color-black);
}

body.is-register,
body.is-rental {
  @include media(max-tablet) {
    overflow: hidden;
    overflow-y: scroll;
  }
}

body.is-device {
  -webkit-overflow-scrolling: touch;
}

* {
  box-sizing: border-box;
}

.app {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index: 1;
}

body.is-device .app {
  overflow: hidden;
  overflow-y: scroll;
}

main {
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
}

body.is-device main {
  position: relative;

  top: unset;
  left: unset;
}

section {
  position: relative;

  width: 100%;
  height: auto;
}

body:not(.is-device) [data-scroll] {
  will-change: transform;
}

.page {
  position: relative;
  z-index: 1;

  &:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
}

.is-transitioning * {
  pointer-events: none !important;
}
