.features {
  &__heading,
  &__items {
    grid-column: 2 / 12;
  }

  &__heading {
    margin-bottom: var(--spacing-30);

    @include media(min-tablet) {
      grid-column: 3 / 6;

      margin-bottom: var(--spacing-60);
    }
  }

  &__items {
    @include media(min-tablet) {
      grid-column: 4 / 11;

      margin-left: var(--spacing-60);
    }
  }

  &__item {
    padding: var(--spacing-30) 0;

    border-bottom: 1px solid var(--color-black-20);

    @include media(max-tablet) {
      display: grid;
      grid-template-columns: repeat(10, 1fr);

      font-size: vw(360, 19);
    }

    @include media(min-tablet) {
      position: relative;

      padding: var(--spacing-60) 0;

      font-size: vw(1440, 23);
    }

    &:first-of-type {
      border-top: 1px solid var(--color-black-20);
    }
  }

  &__title,
  &__description {
    @include media(max-tablet) {
      grid-column: 1 / 9;
    }
  }

  &__title {
    @include media(min-tablet) {
      display: inline-block;

      width: vw(1440, 300);
    }
  }

  &__description {
    color: var(--color-black);

    @include media(min-tablet) {
      display: inline-block;
    }
  }

  &__img-wrap {
    @include media(max-tablet) {
      grid-column: 9 / 11;
      grid-row: 1 / 3;
    }

    @include media(min-tablet) {
      position: absolute;
      top: 0;
      right: calc(#{vw(1440, 50)} * -1);

      width: vw(1440, 178);

      visibility: hidden;

      overflow: hidden;

      opacity: 0;
    }
  }
}

body.is-dark .features {
  &__item {
    border-color: var(--color-white);
  }

  &__title,
  &__description {
    color: var(--color-white);
  }
}
