.logo {
  width: vw(360, 115);
  height: vw(360, 33);

  fill: var(--color-black);

  @include media(min-tablet) {
    width: vw(1440, 165.05);
    height: vw(1440, 38.21);
  }
}

.nav.is-dark .logo {
  fill: var(--color-white);
}

body.is-dark .logo {
  fill: var(--color-white);
}
