.slider {
  width: 100%;

  @include media(min-tablet) {
    height: vw(1440, 950);
  }

  &__slide {
    position: absolute;

    padding: 0 var(--spacing-10);

    width: 100%;
    height: 100%;
  }

  &__content {
    position: absolute;

    color: var(--color-white);

    @include media(min-tablet) {
      top: var(--spacing-90);
      left: var(--spacing-120);

      width: var(--col-4);
    }
  }

  &__chapeau {
    display: block;

    margin-bottom: var(--spacing-30);

    text-transform: uppercase;
  }

  &__heading {
    color: var(--color-white);
  }
}
