:root {
  // colors
  --color-orange-dark: #bc7400;
  --color-orange: #e38c00;
  --color-yellow-light: #fcf9f2;
  --color-yellow: #ffbe02;
  --color-pink: #efee71;
  --color-greige: #fcf9f2;
  --color-blue-darker: #36373c;
  --color-blue-dark: #696c7b;
  --color-blue-light: #e4edef;
  --color-white: #fff;
  --color-black: #111;
  --color-grey: #f6f4f5;
  --color-grey-dark: #838383;
  --color-green: #2a5a47;

  --color-white-13: #ffffff21;
  --color-white-20: #fff3;
  --color-white-70: #ffffffb3;
  --color-black-20: #1113;

  // spacings
  --spacing-5: #{vw(360, 5)};
  --spacing-10: #{vw(360, 10)};
  --spacing-15: #{vw(360, 15)};
  --spacing-20: #{vw(360, 20)};
  --spacing-25: #{vw(360, 25)};
  --spacing-30: #{vw(360, 30)};
  --spacing-35: #{vw(360, 35)};
  --spacing-40: #{vw(360, 40)};
  --spacing-45: #{vw(360, 45)};
  --spacing-50: #{vw(360, 50)};
  --spacing-60: #{vw(360, 60)};
  --spacing-65: #{vw(360, 65)};
  --spacing-70: #{vw(360, 70)};
  --spacing-75: #{vw(360, 75)};
  --spacing-80: #{vw(360, 80)};
  --spacing-90: #{vw(360, 90)};
  --spacing-120: #{vw(360, 120)};
  --spacing-150: #{vw(360, 150)};
  --spacing-180: #{vw(360, 180)};
  --spacing-210: #{vw(360, 210)};
  --spacing-240: #{vw(360, 240)};

  --col-1: calc(100vw / 12 * 1);
  --col-2: calc(100vw / 12 * 2);
  --col-3: calc(100vw / 12 * 3);
  --col-4: calc(100vw / 12 * 4);
  --col-5: calc(100vw / 12 * 5);
  --col-6: calc(100vw / 12 * 6);
  --col-7: calc(100vw / 12 * 7);
  --col-8: calc(100vw / 12 * 8);
  --col-9: calc(100vw / 12 * 9);
  --col-10: calc(100vw / 12 * 10);
  --col-11: calc(100vw / 12 * 11);
  --col-12: calc(100vw / 12 * 12);

  --vh: 100vh;

  // easings
  --ease-expo-in: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  --ease-expo-out: cubic-bezier(0.19, 1, 0.22, 1);
  --ease-expo-in-out: cubic-bezier(1, 0, 0, 1);
  --ease-power3-in: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  --ease-power3-out: cubic-bezier(0.165, 0.84, 0.44, 1);
  --ease-power3-in-out: cubic-bezier(0.77, 0, 0.175, 1);
  --ease-quart-in-out: cubic-bezier(0.77, 0, 0.175, 1);
  --ease-smooth-in-out: cubic-bezier(0.8, 0.16, 0.41, 0.86);
  --fast-ease-in: cubic-bezier(0.54, 0.03, 0.27, 0.91);
  --ease-internal: cubic-bezier(0.34, 0, 0.18, 1);
  --ease-link: cubic-bezier(0.6, 0, 0.4, 1);
  --ease-s-ease-long-out: cubic-bezier(0.38, 0, 0.23, 1);
  --ease-button-vlak-scale: cubic-bezier(0.5, 0, 0.21, 1);
  --ease-soft-ease: cubic-bezier(0, 0, 0.58, 1);
  --ease-text-chapeau: cubic-bezier(0.26, 0.67, 0.48, 1);
  --ease-text-main: cubic-bezier(0.26, 1, 0.48, 1);
  --ease-in-out: cubic-bezier(0.42, 0, 0.58, 1);

  // fonts
  --font-f-messina: messina, sans-serif;
  --font-f-boogy: boogy, serif;
  --font-f-boogy-medium: boogy-medium, serif;

  --font-w-bold: 600;
  --font-w-regular: 400;
  --font-w-light: 300;

  --font-s-heading-xl: #{vw(360, 103)};
  --lh-heading-xl: #{vw(360, 93)};

  --font-s-heading-l: #{vw(360, 100)};
  --lh-heading-l: #{vw(360, 100)};

  --font-s-heading-lg: #{vw(360, 80)};
  --lh-heading-lg: #{vw(360, 71)};

  --font-s-heading-md: #{vw(360, 62)};
  --lh-heading-md: #{vw(360, 55)};

  --font-s-heading-rg: #{vw(360, 50)};
  --lh-heading-rg: #{vw(360, 59)};

  --font-s-heading-rgsm: #{vw(360, 50)};
  --lh-heading-rgsm: #{vw(360, 59)};

  --font-s-heading-sm: #{vw(360, 27)};
  --lh-heading-sm: #{vw(360, 34)};

  --font-s-intro: #{vw(360, 20)};
  --lh-intro: #{vw(360, 29)};

  --font-s-chapeau: #{vw(360, 12)};
  --lh-chapeau: #{vw(360, 12)};

  --font-s-body: #{vw(360, 16)};
  --lh-body: #{vw(360, 25)};

  --font-s-caption: #{vw(360, 16)};
  --lh-caption: #{vw(360, 12)};

  @include media(min-tablet) {
    // spacings
    --spacing-5: #{vw(1440, 5)};
    --spacing-10: #{vw(1440, 10)};
    --spacing-15: #{vw(1440, 15)};
    --spacing-20: #{vw(1440, 20)};
    --spacing-25: #{vw(1440, 25)};
    --spacing-30: #{vw(1440, 30)};
    --spacing-35: #{vw(1440, 35)};
    --spacing-40: #{vw(1440, 40)};
    --spacing-45: #{vw(1440, 45)};
    --spacing-50: #{vw(1440, 50)};
    --spacing-60: #{vw(1440, 60)};
    --spacing-65: #{vw(1440, 65)};
    --spacing-70: #{vw(1440, 70)};
    --spacing-75: #{vw(1440, 75)};
    --spacing-80: #{vw(1440, 80)};
    --spacing-90: #{vw(1440, 90)};
    --spacing-120: #{vw(1440, 120)};
    --spacing-150: #{vw(1440, 150)};
    --spacing-180: #{vw(1440, 180)};
    --spacing-210: #{vw(1440, 210)};
    --spacing-240: #{vw(1440, 240)};

    // fonts
    --font-s-heading-xl: #{vw(1440, 230)};
    --lh-heading-xl: #{vw(1440, 230)};

    --font-s-heading-l: #{vw(1440, 199)};
    --lh-heading-l: #{vw(1440, 199)};

    --font-s-heading-lg: #{vw(1440, 175)};
    --lh-heading-lg: #{vw(1440, 160)};

    --font-s-heading-md: #{vw(1440, 100)};
    --lh-heading-md: #{vw(1440, 95)};

    --font-s-heading-rg: #{vw(1440, 90)};
    --lh-heading-rg: #{vw(1440, 85)};

    --font-s-heading-rgsm: #{vw(1440, 70)};
    --lh-heading-rgsm: #{vw(1440, 65)};

    --font-s-heading-sm: #{vw(1440, 41)};
    --lh-heading-sm: #{vw(1440, 56)};

    --font-s-intro: #{vw(1440, 23)};
    --lh-intro: #{vw(1440, 32)};

    --font-s-chapeau: #{vw(1440, 12)};
    --lh-chapeau: #{vw(1440, 12)};

    --font-s-body: #{vw(1440, 16)};
    --lh-body: #{vw(1440, 28)};

    --font-s-caption: #{vw(1440, 19)};
    --lh-caption: #{vw(1440, 25)};
  }
}
