.c {
  position: relative;

  margin: var(--spacing-120) 0;

  @include media(min-tablet) {
    margin: var(--spacing-120) 0;
  }

  &:last-of-type {
    &.cta {
      margin-bottom: 0;
    }
  }
}

.c.is-grey {
  margin: 0;
  padding: var(--spacing-120) 0;

  background: var(--color-grey);

  @include media(min-tablet) {
    padding: var(--spacing-120) 0;
  }
}

.c.is-grey + .c.is-grey {
  padding-top: 0;
}
