.map {
  &__chapeau,
  &__heading,
  &__body,
  &__button {
    grid-column: 2 / -2;
  }

  &__body {
    @include media(min-tablet) {
      grid-column: 2 / span 4;
    }
  }

  &__button {
    @include media(min-tablet) {
      align-self: end;

      width: fit-content;
    }
  }

  &__chapeau + &__heading {
    margin-top: var(--spacing-10);
  }

  &__heading + &__body {
    margin-top: var(--spacing-30);
  }

  &__map-wrap {
    grid-column: 2 / -2;

    margin-top: var(--spacing-60);

    iframe {
      width: 100%;

      height: vw(360, 360);

      @include media(min-tablet) {
        height: vw(1440, 610);
      }
    }
  }
}
