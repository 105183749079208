.preloader {
  position: fixed;
  top: 0;
  left: 0;

  z-index: 99;

  width: 100%;
  height: 100vh;

  pointer-events: none;

  background-color: var(--color-yellow-light);

  &__inner {
    width: 100%;
    height: 100vh;

    background: transparent;

    border-top: vw(1440, 225);
    border-right: vw(1440, 450);
    border-bottom: vw(1440, 225);
    border-left: vw(1440, 450);

    transform: translateY(calc(100% - #{vw(1440, 225)}));

    border-color: var(--color-yellow-light);
    border-style: solid;
  }
}
