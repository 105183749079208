.richtext {
  a {
    border-bottom: vw(360, 1) solid var(--color-black);

    @include media(min-tablet) {
      border-bottom: vw(1440, 1) solid var(--color-black);
    }
  }

  p + p {
    margin-top: var(--spacing-30);
  }

  h3 + p {
    margin-top: var(--spacing-30);
  }

  p + h3 {
    margin-top: var(--spacing-30);
  }
}
