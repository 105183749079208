.text-image {
  &__link {
    display: inline-flex;
    align-items: center;

    margin-top: var(--spacing-30);

    color: var(--color-orange);
    text-transform: uppercase;

    @include media(min-tablet) {
      margin-top: var(--spacing-60);
    }
  }

  &__link-icon {
    margin-left: var(--spacing-5);

    width: vw(360, 4.94);
    height: vw(360, 5.68);

    fill: var(--color-orange);

    @include media(min-tablet) {
      width: vw(1440, 4.94);
      height: vw(1440, 5.68);
    }
  }

  /* Intro */
  &.is-intro &__img-wrap {
    grid-column: 4 / 10;

    @include media(min-tablet) {
      grid-column: 3 / 7;
    }
  }

  &.is-intro &__intro {
    grid-column: 2 / 12;

    z-index: 2;

    margin-top: calc(#{vw(360, 35)} * -1);

    text-indent: vw(360, 100);

    @include media(min-tablet) {
      grid-column: 5 / 12;

      margin-top: calc(var(--spacing-180) * -1);

      padding: 0 var(--spacing-90) 0 var(--spacing-60);

      text-indent: vw(1440, 150);
    }
  }

  &.is-intro &__content {
    grid-column: 3 / 12;

    @include media(min-tablet) {
      grid-column: 7 / 11;

      position: relative;

      padding-right: var(--spacing-60);
    }
  }

  /* Heading */
  &.is-heading.is_panorama {
    @include media(min-tablet) {
      &.is_portrait {

        min-height: vw(1440, 700);
      }

      &.is_landscape {
        height: vw(1440, 459);
      }
    }
  }

  &.is-heading &__img-wrap {
    @include media(max-tablet) {
      margin-top: var(--spacing-30);
    }

    grid-column: 2 / 12;

    @include media(min-tablet) {
      grid-column: 7 / 12;

      justify-self: center;
      width: 100%;
    }
  }

  &.is-heading.is_landscape &__img-wrap {
    @include media(min-tablet) {
      grid-column: 6 / span 6;
    }
  }

  &.is-heading.image-left &__img-wrap {
    @include media(min-tablet) {
      grid-column: 2 / 7;
    }
  }

  &.is-heading.is_landscape.image-left &__img-wrap {
    @include media(min-tablet) {
      grid-column: 2 / span 6;
    }
  }

  &.is-heading &__content {
    grid-column: 2 / 12;
    grid-row: 1;

    @include media(min-tablet) {
      grid-column: 2 / 6;
    }
  }

  &.is-heading.is_landscape &__content {
    @include media(min-tablet) {
      grid-column: 2 / span 3;
    }
  }

  &.is-heading.image-left &__content {
    @include media(min-tablet) {
      grid-column: 8 / 12;
    }
  }

  &.is-heading.is_landscape.image-left &__content {
    @include media(min-tablet) {
      grid-column: 9 / span 3;
    }
  }

  &.is-heading &__chapeau + &__heading {
    margin-top: var(--spacing-30);
  }

  &.is-heading.image-left &__panorama {
    @include media(min-tablet) {
      left: var(--col-1);
      right: unset;
    }
  }

  &.is-heading.image-right &___panorama {
    @include media(min-tablet) {
      right: var(--col-1);
    }
  }

  /* List */
  &.is-list,
  &.is-list .container {
    @include media(min-tablet) {
      min-height: vw(1440, 700);
    }
  }

  &.is-list &__panorama,
  &.is-heading &__panorama {
    @include media(max-tablet) {
      grid-column: 1 / -1;

      margin-top: var(--spacing-30);
    }

    @include media(min-tablet) {
      justify-self: center;

      position: absolute;
      right: var(--spacing-60);

      z-index: 2;

      height: 100%;
    }
  }

  &.is-list &__img-wrap,
  &.is-heading &__panorama-wrap {
    margin: 0 auto;

    width: vw(360, 300);
    height: vw(360, 388);

    overflow: hidden;

    cursor: none;
    background-position: 50% 50%;

    background-repeat: no-repeat;
    background-size: auto 100%;
    background-color: var(--color-white);

    @include media(min-tablet) {
      justify-self: center;

      width: vw(1440, 605);
      height: 100%;
      min-height: vw(1440, 700);

      &.is_landscape {
        width: vw(1440, 720);
        height: vw(1440, 459);

        min-height: auto;

        background-size: 100%;
      }
    }
  }

  &.is-list &__close-icon,
  &.is-heading &__close-icon {
    position: absolute;

    top: var(--spacing-15);
    right: var(--spacing-15);

    width: var(--spacing-30);
    height: var(--spacing-30);

    visibility: hidden;
    opacity: 0;

    stroke: var(--color-white);
  }

  &.is-list &__panorama.is-light &__close-icon,
  &.is-heading &__panorama.is-light &__close-icon {
    stroke: var(--color-black);
  }

  &.is-list &__drag-overlay,
  &.is-heading &__drag-overlay {
    display: inline-flex;
    align-items: flex-end;
    justify-content: space-around;

    position: fixed;

    bottom: 0;

    padding-bottom: var(--spacing-30);

    width: 100%;
    height: vw(360, 240);

    visibility: hidden;
    font-size: vw(360, 12);

    text-transform: uppercase;

    color: white;

    background: linear-gradient(180deg, rgba(255, 255, 255, 0%) 0%, #000c 100%);
    opacity: 0;

    @include media(min-tablet) {
      display: none;
    }
  }

  &.is-list.image-left &__img-wrap {
    @include media(min-tablet) {
      grid-column: 1 / 7;
    }
  }

  &.is-list &__content {
    grid-column: 2 / 12;
    grid-row: 1;

    @include media(min-tablet) {
      grid-column: 3 / 7;
      align-self: center;

      padding-right: var(--spacing-60);
    }
  }

  &.is-list &__chapeau + &__heading {
    margin-top: var(--spacing-30);
  }

  &.is-list &__list {
    display: flex;
    flex-direction: column;

    @include media(max-tablet) {
      row-gap: var(--spacing-15);
    }

    margin-top: var(--spacing-30);
  }

  &.is-list &__list-item {
    display: flex;
    flex-direction: column;

    border-bottom: 1px solid var(--color-black-20);

    &:last-child {
      border-bottom: none;
    }

    @include media(min-tablet) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      padding: var(--spacing-5) 0;
      line-height: vw(1440, 25);
    }
  }

  &.is-list &__list-item-key {
    text-transform: uppercase;

    font-size: vw(360, 12);

    @include media(min-tablet) {
      font-size: vw(1440, 12);
    }
  }

  &.is-list &__downloads {
    margin-top: var(--spacing-45);
  }

  &.is-list &__download-list {
    @include media(min-tablet) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &.is-list &__download-link {
    text-decoration: underline;
  }

  /* Large */
  &.is-large &__content {
    grid-column: 2 / 12;

    z-index: 2;

    text-align: center;
  }

  &.is-large &__img-wrap {
    justify-self: center;
    grid-column: 1 / 13;

    margin-top: var(--spacing-30);

    width: calc(100% - var(--spacing-30));

    @include media(min-tablet) {
      margin-top: calc(var(--spacing-50) * -1);

      width: calc(100% - var(--spacing-120));
    }
  }

  &.is-large &__chapeau + &__heading {
    margin-top: var(--spacing-30);
  }

  /* Carousel */
  &.is-carousel &__carousel {
    @include media(max-tablet) {
      grid-column: 1 / 13;
      justify-self: center;

      width: calc(100% - var(--spacing-30));

      height: vw(360, 370);
    }

    position: relative;

    overflow: hidden;

    cursor: none;

    @include media(min-tablet) {
      height: vw(1440, 875);
    }
  }

  &__carousel-nav {
    display: flex;
    position: absolute;
    z-index: 3;

    width: 100%;
    height: 100%;
  }

  &__carousel-button {
    width: 50%;
    height: 100%;
  }

  &.is-carousel &__img-wrap {
    position: absolute;

    z-index: 0;

    width: 100%;

    &:nth-of-type(1) {
      z-index: 2;
    }

    &:nth-of-type(2) {
      z-index: 1;
    }
  }

  &.is-carousel &__content {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;

    @include media(max-tablet) {
      grid-column: 2 / 12;

      margin-top: var(--spacing-30);
    }
  }

  &.is-carousel &__heading {
    @include media(max-tablet) {
      margin-top: var(--spacing-40);
    }
  }

  &.is-carousel &__chapeau + &__heading {
    margin-top: var(--spacing-30);
  }

  &.is-carousel &__heading + &__intro {
    margin-top: var(--spacing-30);

    font-size: vw(360, 16);
    line-height: vw(360, 28);

    @include media(min-tablet) {
      font-size: vw(1440, 16);
      line-height: vw(1440, 28);
    }
  }

  &.is-carousel.image-left &__carousel {
    @include media(min-tablet) {
      grid-column: 1 / 8;
      justify-self: flex-end;

      width: calc(100% - var(--spacing-60));
    }
  }

  &.is-carousel.image-left &__content {
    @include media(min-tablet) {
      grid-column: 8 / 12;

      padding-left: var(--spacing-60);
    }
  }

  &.is-carousel.image-right &__carousel {
    @include media(min-tablet) {
      grid-column: 6 / 13;

      width: calc(100% - var(--spacing-60));
    }
  }

  &.is-carousel.image-right &__content {
    @include media(min-tablet) {
      grid-column: 2 / 6;
      grid-row: 1;

      padding-right: var(--spacing-60);
    }
  }

  &.is-carousel &__chapeau {
    color: var(--color-black);
  }

  &.is-carousel &__nav {
    @include media(max-tablet) {
      position: absolute;
      top: calc((#{vw(360, 14.63)} / 2) * -1);
      right: calc(var(--spacing-15) * -1);
      margin: 0;
    }

    display: flex;

    margin-left: calc(var(--spacing-15) * -1);
    margin-top: auto;
  }

  &.is-carousel &__svg {
    padding: var(--spacing-15);

    width: vw(360, 14.63);
    height: vw(360, 10.45);

    box-sizing: content-box;

    @include media(min-tablet) {
      width: vw(1440, 14.63);
      height: vw(1440, 10.45);

      cursor: pointer;
    }
  }

  &.is-carousel &__intro + &__nav {
    @include media(min-tablet) {
      margin-top: var(--spacing-60);
    }
  }

  &.is-carousel &__downloads {
    @include media(max-tablet) {
      margin-top: var(--spacing-60);
    }

    @include media(min-tablet) {
      position: absolute;
      bottom: 0;
    }
  }

  &.is-carousel &__download-list {
    display: flex;
    flex-direction: column;

    row-gap: var(--spacing-15);

    margin-top: var(--spacing-15);
  }

  &.is-carousel &__download-link {
    text-decoration: underline;

    &:hover {
      color: var(--color-blue-dark);
    }
  }

  /* general lint fixes */
  &.is-heading &__heading + &__body {
    margin-top: var(--spacing-30);
  }

  &.is-intro &__intro + &__content {
    margin-top: var(--spacing-60);

    @include media(min-tablet) {
      margin-top: var(--spacing-120);
    }
  }

  body.is-dark &.is-carousel {
    .text-image__intro,
    .text-image__chapeau,
    .text-image__download-link {
      color: var(--color-white);
    }

    .text-image__counter {
      color: var(--color-greige);
    }

    .text-image__download-link {
      &:hover {
        color: var(--color-greige);
      }
    }

    .text-image__svg {
      fill: var(--color-white);
    }
  }

  &__carousel_button {
    margin-top: var(--spacing-60);
  }
}
