.button {
  display: inline-flex;
  align-items: center;

  column-gap: var(--spacing-15);

  margin-top: var(--spacing-30);

  text-transform: uppercase;

  color: var(--color-black);

  @include media(min-tablet) {
    font-size: vw(1440, 16);
  }

  &__content {
    display: inline-flex;
    row-gap: var(--spacing-30);
    flex-direction: column;

    height: vw(360, 16);

    overflow: hidden;

    line-height: vw(360, 16);

    @include media(min-tablet) {
      height: vw(1440, 16);

      line-height: vw(1440, 16);
    }
  }

  &__title {
    transition: transform 0.77s cubic-bezier(0.36, 0, 0, 1);
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    width: vw(360, 57.2);
    height: vw(360, 57.2);

    overflow: hidden;

    border-radius: 50%;

    @include media(min-tablet) {
      width: vw(1440, 57.2);
      height: vw(1440, 57.2);
    }

    > * {
      position: absolute;

      transition: 0.77s cubic-bezier(0.36, 0, 0, 1);
      transition-property: transform, opacity;
    }
  }

  &__play {
    width: vw(360, 11);
    height: vw(360, 10);

    @include media(min-tablet) {
      width: vw(1440, 11);
      height: vw(1440, 10);
    }
  }

  &__outer-circle,
  &__inner-circle {
    width: vw(360, 57.2);
    height: vw(360, 57.2);

    border-radius: 50%;

    @include media(min-tablet) {
      width: vw(1440, 57.2);
      height: vw(1440, 57.2);
    }
  }

  &__outer-circle {
    border: 1px solid var(--color-black);
  }

  &__inner-circle {
    background: var(--color-black);
    opacity: 0;
    transform: scale(0);
  }

  &__arrow,
  &__play {
    fill: var(--color-black);

    &--secondary {
      transform: translateX(calc(#{vw(1440, 57.2)} * -1));

      fill: var(--color-white);
    }
  }

  &.is-light {
    color: var(--color-white);
  }

  &.is-light &__outer-circle {
    border-color: var(--color-white);
  }

  &.is-light &__inner-circle {
    background: var(--color-white);
  }

  &.is-light &__arrow,
  &.is-light &__play {
    fill: var(--color-white);

    &--secondary {
      fill: var(--color-black);
    }
  }

  &:hover {
    .button {
      &__title {
        transform: translateY(calc((#{vw(360, 16)} + #{vw(360, 30)}) * -1));
      }

      @include media(min-tablet) {
        &__title {
          transform: translateY(calc((#{vw(1440, 16)} + #{vw(1440, 30)}) * -1));
        }
      }

      &__outer-circle {
        transform: scale(0.9);
      }

      &__inner-circle {
        opacity: 1;
        transform: scale(1);
      }

      &__arrow,
      &__play {
        &--primary {
          transform: translateX(vw(1440, 57.2));
        }

        &--secondary {
          transform: translateX(0);
        }
      }
    }
  }
}

body.is-dark .button {
  color: white;

  &__outer-circle {
    border: 1px solid var(--color-white);
  }

  &__inner-circle {
    background: var(--color-white);
  }

  &__arrow,
  &__play {
    fill: var(--color-white);

    &--secondary {
      fill: var(--color-black);
    }
  }
}
