.text {
  &.image-next.is-white {
    margin-bottom: var(--spacing-60);

    @include media(min-tablet) {
      margin-bottom: var(--spacing-120);
    }
  }

  &.image-next.is-white + .c.image.is-white {
    margin-top: var(--spacing-60);

    @include media(min-tablet) {
      margin-top: var(--spacing-120);
    }
  }

  &.image-next.is-grey {
    padding-bottom: var(--spacing-60);

    @include media(min-tablet) {
      padding-bottom: var(--spacing-120);
    }
  }

  &__chapeau {
    grid-column: 2 / 12;

    @include media(min-tablet) {
      grid-column: 3 / 9;
    }
  }

  &__intro {
    grid-column: 2 / 12;

    text-indent: vw(360, 100);
    letter-spacing: spacing(-80);
    font-size: vw(360, 27);
    line-height: vw(360, 34);

    @include media(min-tablet) {
      grid-column: 3 / 10;

      text-indent: vw(1440, 150);
      letter-spacing: spacing(-80);
      font-size: vw(1440, 40);
      line-height: vw(1440, 48);
    }
  }

  &.is-quote &__intro {
    grid-column: 2 / 12;
    text-indent: 0;

    @include media(min-tablet) {
      grid-column: 3 / 11;
    }
  }

  &__quotee {
    grid-column: 2 / 12;

    margin-top: var(--spacing-30);

    @include media(min-tablet) {
      grid-column: 3 / 10;
    }
  }

  &__chapeau + &__heading,
  &__heading + &__body,
  &__body + &__body-right {
    margin-top: var(--spacing-30);

    @include media(min-tablet) {
      margin-top: var(--spacing-60);
    }
  }

  &.has-one-column &__chapeau,
  &.has-one-column &__heading {
    grid-column: 2 / 10;

    @include media(min-tablet) {
      grid-column: 2 / 9;
    }
  }

  &.has-one-column &__body {
    grid-column: 3 / 12;

    @include media(min-tablet) {
      grid-column: 6 / 10;
    }
  }

  &.has-two-columns &__heading {
    grid-column: 2 / 12;

    @include media(min-tablet) {
      grid-column: 3 / 12;
    }
  }

  &.has-two-columns &__body {
    grid-column: 3 / 12;

    @include media(min-tablet) {
      grid-column: 4 / 7;
    }
  }

  &.has-two-columns &__body-right {
    grid-column: 3 / 12;

    @include media(min-tablet) {
      grid-column: 8 / 11;
    }
  }

  &.has-one-column &__heading + &__body {
    margin-top: var(--spacing-60);

    @include media(min-tablet) {
      margin-top: var(--spacing-120);
    }
  }

  &.is-single-line &__heading {
    grid-column: 2 / -2;

    font-size: vw(360, 45);
    line-height: vw(360, 55);

    @include media(min-tablet) {
      grid-column: 2 / span 5;

      font-size: vw(1440, 70);
      line-height: vw(1440, 84);
    }
  }

  &.is-single-line &__content {
    grid-column: 3 / -2;

    @include media(min-tablet) {
      grid-column: 8 / span 4;

      margin-top: var(--spacing-20);
    }
  }

  &.is-single-line &__download-list {
    margin-top: var(--spacing-20);

    a {
      color: var(--color-black);
      text-decoration: underline;
    }
  }

  body.is-dark &.is-single-line {
    .text__download-list {
      a {
        color: var(--color-white);
      }
    }
  }

  body.is-dark &.is-intro {
    .text__intro {
      color: var(--color-white);
    }

    .text__quotee {
      color: var(--color-white);
    }
  }

  &__button {
    grid-column: 3 / 12;
    margin-top: var(--spacing-60);

    @include media(min-tablet) {
      grid-column: 6 / 10;
    }
  }
}
