.menu {
  position: fixed;
  top: 0;
  left: 0;

  z-index: 2;

  width: 100%;
  height: 100%;

  visibility: hidden;

  background-color: var(--color-black);

  opacity: 0;

  /* Top Menu */
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: absolute;

    padding: var(--spacing-15);

    width: 100%;

    @include media(min-tablet) {

      z-index: 2;
      padding: var(--spacing-30);
    }
  }

  &__lang-switch {
    position: relative;

    padding-right: var(--spacing-20);

    height: vw(360, 25);

    overflow: hidden;

    cursor: pointer;

    pointer-events: all;

    @include media(min-tablet) {
      height: vw(1440, 25);
    }
  }

  &__lang-switch-link,
  &__lang-switch-item {
    text-transform: uppercase;
    color: var(--color-white);

    @include media(min-tablet) {
      font-size: vw(1440, 16);
      line-height: vw(1440, 25);
      letter-spacing: spacing(170);
    }
  }

  &__lang-switch-link {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  &__lang-icon {
    position: absolute;
    top: 12.5px;
    right: 0;

    width: 6.66px;
    height: 3.57px;

    transform: translateY(-50%);
    stroke: var(--color-white);
    fill: none;

    @include media(min-tablet) {
      top: vw(1440, 10);

      width: vw(1440, 12.98);
      height: vw(1440, 6.49);
    }
  }

  body.is-dark & {
    &__lang-switch-link,
    &__lang-switch-item {
      color: var(--color-white);
    }

    &__lang-icon {
      stroke: var(--color-white);
    }
  }

  &__logo {
    @include media(max-tablet) {
      display: none;
    }

    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    fill: var(--color-white);
  }

  &__close {
    width: vw(360, 110);
    height: vw(360, 110);

    pointer-events: all;

    border: 1px solid var(--color-white);

    border-radius: 50%;

    will-change: transform;

    > * {
      position: absolute;
      top: 50%;
      left: 50%;

      pointer-events: none;

      transform: translate(-50%, -50%);
    }

    @include media(min-tablet) {
      top: var(--spacing-30);
      right: var(--spacing-30);

      width: vw(1440, 110);
      height: vw(1440, 110);
    }
  }

  &__close-icon {
    z-index: 2;

    width: vw(360, 22.63);
    height: vw(360, 22.63);

    fill: none;
    stroke: var(--color-white);
    stroke-width: 2;

    @include media(min-tablet) {
      width: vw(1440, 22.63);
      height: vw(1440, 22.63);
    }
  }

  /* Main menu */
  &__main {
    display: flex;
    justify-content: space-around;

    position: absolute;
    top: 50%;
    left: 50%;

    width: 100%;

    transform: translate(-50%, -50%);
  }

  &__main-item {
    overflow: hidden;
  }

  &__main-list {
    visibility: hidden;

    text-align: center;

    opacity: 0;
  }

  &__main-link {
    overflow: hidden;

    font-family: var(--font-f-boogy);

    font-size: vw(360, 46);
    line-height: vw(360, 46);

    color: var(--color-white);

    @include media(min-tablet) {
      font-size: vw(1440, 60);
      line-height: vw(1440, 60);
      letter-spacing: spacing(10);
    }
  }

  @media (hover: hover) {
    &__main-link {
      transition: color 200ms var(--ease-soft-ease);
    }

    &__main-list:hover &__main-link {
      color: var(--color-greige);

      &:not(:hover) {
        color: var(--color-white-20);
      }
    }
  }

  /* Bottom Menu */
  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: absolute;
    bottom: 0;

    padding: var(--spacing-15);

    width: 100%;

    @include media(min-tablet) {
      padding: var(--spacing-30);
    }
  }

  &__bottom--right {
    display: flex;

    column-gap: vw(360, 30);

    @include media(min-tablet) {
      column-gap: vw(1440, 30);
    }
  }

  &__secondary-link {
    text-transform: uppercase;

    color: var(--color-white);
  }

  &__button {
    margin: 0;

    color: var(--color-white);
  }

  /* Background Image */
  &__background {
    @include media(max-tablet) {
      display: none;
    }

    position: absolute;

    z-index: -1;

    width: 100%;
    height: 100%;

    visibility: hidden;
    opacity: 0;
  }

  &__img-mask,
  &__img-wrap,
  &__img {
    position: absolute;

    width: 100%;
    height: 100%;
  }

  &__img-mask {
    bottom: 0;

    z-index: 0;

    transform-origin: bottom;
  }

  &__img-wrap {
    top: 0;

    visibility: hidden;
    opacity: 0;

    transform-origin: bottom;
  }

  &__img {
    object-fit: cover;
  }
}
