.back-to-top {
  position: fixed;
  right: var(--spacing-15);
  bottom: var(--spacing-15);

  z-index: 2;

  width: var(--spacing-60);
  height: var(--spacing-60);

  visibility: hidden;
  opacity: 0;

  border: 1px solid var(--color-black);
  border-radius: 50%;

  @include media(min-tablet) {
    right: var(--spacing-30);
    bottom: var(--spacing-30);
  }

  &.is-dark {
    color: var(--color-white);
  }

  &__icon {
    width: vw(360, 15.56);
    height: vw(360, 6.41);

    transform: rotate(-90deg);

    fill: var(--color-black);

    @include media(min-tablet) {
      width: vw(1440, 15.56);
      height: vw(1440, 6.41);
    }
  }
}

body.is-dark .back-to-top {
  border-color: var(--color-white);

  &__icon {
    fill: var(--color-white);
  }
}
