@font-face {
  font-family: boogy;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src:
    url('../src/assets/fonts/boogy.woff2') format('woff2'),
    url('../src/assets/fonts/boogy.woff') format('woff');
}

@font-face {
  font-family: boogy-medium;
  font-style: normal;
  font-display: swap;
  src:
    url('../src/assets/fonts/boogy-medium.woff2') format('woff2'),
    url('../src/assets/fonts/boogy-medium.woff') format('woff');
}

@font-face {
  font-family: messina;
  font-style: normal;
  font-display: swap;
  src:
    url('../src/assets/fonts/messina.woff2') format('woff2'),
    url('../src/assets/fonts/messina.woff') format('woff');
}
