.house-types {
  &__heading {
    display: block;

    margin-bottom: var(--spacing-60);

    width: 100%;

    text-align: center;

    text-transform: uppercase;
  }

  // Mobile
  @include media(max-tablet) {
    &__slider {
      display: none;
    }

    &__img-wrap {
      margin: 0 0 0 var(--spacing-30);

      width: var(--spacing-90);
      height: var(--spacing-90);
    }

    &__row {
      display: flex;
      align-items: center;
      justify-content: center;

      &:nth-child(odd) {
        flex-direction: row-reverse;

        .house-types__img-wrap {
          margin: 0 var(--spacing-30) 0 0;
        }
      }
    }

    &__row + &__row {
      margin-top: var(--spacing-30);
    }

    &__link {
      font-size: vw(360, 27);
      letter-spacing: spacing(-80);
    }
  }

  // Desktop

  @include media(min-tablet) {
    &__container {
      display: none;
    }

    &__slider {
      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;

      margin: 0 auto;

      width: calc(100% - var(--spacing-120));
      height: vw(1440, 700);

      overflow: hidden;

      text-align: center;

      background: var(--color-black);
    }

    &__imgs {
      display: block;

      position: absolute;
      top: 50%;
      left: 50%;

      z-index: 1;

      width: 100%;
      height: 100%;

      visibility: hidden;

      opacity: 0;

      transform: translate(-50%, -50%);
    }

    &__imgs-overlay {
      position: absolute;
      top: 50%;
      left: 50%;

      z-index: 999;

      width: calc(100% + 2px);
      height: 100%;

      background: var(--color-black);

      opacity: 0.5;

      transform: translate(-50%, -50%);
    }

    &__img-mask {
      display: block;

      position: absolute;
      top: 0;

      z-index: 0;

      width: 100%;
      height: 100%;

      visibility: hidden;

      overflow: hidden;

      opacity: 0;

      transform-origin: bottom;

      will-change: transform;
    }

    &__img-wrap {
      position: absolute;
      top: 0;

      width: 100%;
      height: 100%;

      transform-origin: bottom;

      will-change: transform;

      .img {
        width: 100%;
      }
    }

    &__list {
      z-index: 2;
    }

    &__item {
      overflow: hidden;
    }

    &__link {
      display: block;

      color: var(--color-white);

      font-size: vw(1440, 41);
      letter-spacing: spacing(-80);
      line-height: vw(1440, 55);
    }

    @media (hover: hover) {
      &__link {
        transition: color 200ms var(--ease-soft-ease);
      }

      &__list:hover &__link {
        &:not(:hover) {
          color: var(--color-white-20);
        }
      }
    }
  }
}
