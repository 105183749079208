.cta {
  display: block;

  &__img-wrap {
    @include media(min-tablet) {
      --aspect-ratio: 1.519 !important;
    }

    &::after {
      content: '';

      position: absolute;
      top: 0;

      width: 100%;
      height: 100%;

      background-color: var(--color-black);
      opacity: 0.2;
    }

    .img {
      @include media(min-tablet) {
        height: calc(100% + var(--col-1));
      }
    }
  }

  &__content {
    position: absolute;
    left: var(--col-1);

    @include media(min-tablet) {
      left: var(--spacing-120);
    }

    &.is-top {
      top: var(--spacing-60);

      @include media(min-tablet) {
        top: var(--spacing-90);
      }
    }

    &.is-bottom {
      bottom: var(--spacing-60);

      @include media(min-tablet) {
        bottom: var(--spacing-90);
      }
    }
  }

  &__heading {
    color: var(--color-black);

    &.is-white {
      color: var(--color-white);
    }
  }

  &:hover {
    cursor: none;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-self: flex-end;
    grid-column: 9 / 11;

    column-gap: var(--spacing-30);

    margin-top: var(--spacing-30);

    font-size: vw(360, 12);
    text-transform: uppercase;

    color: var(--color-white);

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;

      width: vw(360, 57.2);
      height: vw(360, 57.2);
      border: 1px solid var(--color-white);

      border-radius: 50%;

      @include media(min-tablet) {
        width: vw(1440, 57.2);
        height: vw(1440, 57.2);
      }
    }

    &__arrow {
      fill: var(--color-white);
    }

    @include media(min-tablet) {
      font-size: vw(1440, 16);
    }
  }
}

body.is-dark .cta {
  &__heading {
    color: var(--color-greige);
  }
}
