.transition {
  position: fixed;
  top: 0;
  left: 0;

  z-index: 99;

  width: 100%;
  height: 100vh;

  visibility: hidden;

  overflow: hidden;

  pointer-events: none;

  background: var(--color-greige);

  opacity: 0;
}
