.video {
  &__text {
    grid-column: 2 / 12;
    position: relative;
    z-index: 2;
    text-align: center;

    @include media(min-tablet) {
      grid-column: 3 / 11;
    }
  }

  &__video {
    grid-column: 1 / 13;
    position: relative;
    z-index: 1;
    margin: vw(360, -15) vw(360, 15) 0 vw(360, 15);
    height: vw(360, 200);

    @include media(min-tablet) {
      margin: vw(1440, -35) vw(1440, 60) 0 vw(1440, 60);
      height: vw(1440, 600);
    }

    &.no-heading {
      margin-top: 0;
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
