.subscribe {
  &__container {
    margin: 0 var(--spacing-15);
    padding: var(--spacing-60) var(--spacing-15);

    overflow: hidden;

    background: var(--color-grey);

    @include media(min-tablet) {
      display: flex;
      justify-content: space-between;

      margin: 0 var(--spacing-30);
      padding: var(--spacing-90);
    }
  }

  &__heading {
    letter-spacing: spacing(-80);

    @include media(max-tablet) {
      margin-bottom: var(--spacing-30);
      font-size: vw(360, 27);
    }

    @include media(min-tablet) {
      width: var(--col-4);

      font-size: vw(1440, 32);
    }
  }

  &__form {
    display: block;

    overflow: hidden;

    @include media(min-tablet) {
      width: var(--col-5);
    }
  }

  &__label {
    display: block;

    margin-bottom: var(--spacing-15);

    text-transform: uppercase;
  }

  &__field {
    display: block;

    padding: 0 var(--spacing-30);

    width: 100%;

    line-height: vw(360, 65);

    background-color: var(--color-white-70);

    border: none;

    @include media(min-tablet) {
      line-height: vw(1440, 65);
    }
  }

  &__link {
    text-decoration: underline;
  }

  &__button {
    float: right;
  }

  &__hidden {
    display: none;

    visibility: hidden;
    opacity: 0;
  }
}

body.is-dark .subscribe {
  &__container {
    background: var(--color-greige);
  }

  &__heading {
    color: var(--color-black);
  }
}
