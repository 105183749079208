.cursor {
  position: fixed;

  z-index: 99;

  width: vw(1440, 110);
  height: vw(1440, 110);

  pointer-events: none;

  border-radius: 100%;

  &__inner {
    background: var(--color-white);
  }

  &__inner,
  &__inner--secondary {
    position: absolute;
    top: 0;
    left: 0;

    width: vw(1440, 110);
    height: vw(1440, 110);

    overflow: hidden;

    line-height: vw(1440, 110);

    text-align: center;

    border-radius: 100%;

    transform: scale(0);
  }

  &__inner--secondary {
    border: 1px solid var(--color-white);
  }

  &__text,
  &__text--secondary {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    font-size: vw(1440, 12);
    line-height: vw(1440, 110);
    text-transform: uppercase;

    color: var(--color-black);
  }

  &__text--secondary {
    visibility: hidden;

    color: var(--color-white);

    opacity: 0;
  }

  &__carousel &__text--secondary {
    visibility: visible;
    color: var(--color-black);
    opacity: 1;
  }
}
