.video-player {
  &__wrap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 98;

    width: 100%;
    height: 100%;

    visibility: hidden;

    background-color: var(--color-black);

    opacity: 0;
  }

  &__button {
    position: absolute;
    z-index: 99;
    top: var(--spacing-30);
    right: var(--spacing-30);

    width: vw(360, 110);
    height: vw(360, 110);

    cursor: pointer;

    pointer-events: all;

    border: 1px solid var(--color-white);

    border-radius: 50%;

    will-change: transform;

    > * {
      position: absolute;
      top: 50%;
      left: 50%;

      pointer-events: none;

      transform: translate(-50%, -50%);
    }

    @include media(min-tablet) {
      width: vw(1440, 110);
      height: vw(1440, 110);
    }
  }

  &__close-icon {
    z-index: 2;

    width: vw(360, 22.63);
    height: vw(360, 22.63);

    fill: none;
    stroke: var(--color-white);
    stroke-width: 2;

    @include media(min-tablet) {
      width: vw(1440, 22.63);
      height: vw(1440, 22.63);
    }
  }

  &__player {
    width: 100%;
    height: 100%;
  }
}
